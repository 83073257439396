<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入筛选文字"
          clearable
      /></el-col>
      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.type"
          clearable
          placeholder="出库类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.status"
          clearable
          placeholder="出库单状态"
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-date-picker
          size="mini"
          v-model="timer"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>

      <el-button size="small" type="primary" @click="flashTable"
        >查询订单</el-button
      >
      <el-button
        size="small"
        type="primary"
        style="float: right"
        @click="pushAddPage"
        >新增订单</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table
        :data="outStorageDataList"
        row-key="id"
        style="width: 100%"
        @expand-change="expandChange"
        :expand-row-keys="expandRowIds"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand_box">
              <el-table
                ref="productRef"
                :data="scope.row.productList"
                :summary-method="getSummaries"
                :show-summary="true"
                v-loading="scope.row.loading"
                border
              >
                <el-table-column
                  type="index"
                  width="80"
                  label="#"
                ></el-table-column>
                <el-table-column
                  prop="productName"
                  show-overflow-tooltip
                  label="商品名称"
                >
                </el-table-column>
                <el-table-column prop="productAmount" label="商品数量">
                </el-table-column>
                <el-table-column prop="productPrice" label="商品价格">
                </el-table-column>
                <el-table-column label="型号">
                  <template slot-scope="scope">
                    {{ scope.row.modelName || scope.row.productModel || '无' }}
                  </template>
                </el-table-column>
                <el-table-column label="规格">
                  <template slot-scope="scope">
                    {{ scope.row.productSpecs || '无' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="remark"
                  show-overflow-tooltip
                  label="商品备注"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column label="订单编号" width="220">
          <template slot-scope="scope">
            <el-link
              style="font-size: 12px"
              :underline="false"
              @click="handleCopy(scope.row.number, $event)"
              >{{ scope.row.number }}</el-link
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="outStorageTypeDisplayName" label="出库单类型">
        </el-table-column> -->
        <!-- <el-table-column prop="orderAmount" label="订单金额"> </el-table-column> -->
        <el-table-column prop="tradeAmount" label="金额"> </el-table-column>

        <el-table-column
          width="120"
          prop="outStorageStateDisplayName"
          label="出库单状态"
        >
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.outStorageState)" effect="dark">
              {{ scope.row.outStorageStateDisplayName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="订单创建时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="courierName" label="快递选择"> </el-table-column>
        <el-table-column label="商城状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isClose" type="danger" effect="dark">
              交易关闭
            </el-tag>
            <el-tag v-else effect="dark"> 正常 </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="owinUserName" label="客户名称">
        </el-table-column>
        <el-table-column prop="companyName" label="个人/公司">
        </el-table-column>
        <el-table-column
          prop="associatedOrderNumber"
          label="关联订单号"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          width="100"
          show-overflow-tooltip
          prop="remark"
          label="备注"
        >
        </el-table-column>

        <el-table-column label="有效金额" show-overflow-tooltip
          ><template slot-scope="scope">
            {{
              `支付金额(${scope.row.payAmount})+薏米抵扣(${
                scope.row.yimiTotalAmount
              })-薏米赠送(${scope.row.yimiGifAmount})=${
                scope.row.payAmount +
                scope.row.yimiTotalAmount -
                scope.row.yimiGifAmount
              }`
            }}
          </template>
        </el-table-column>

        <el-table-column width="200" fixed="right" label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="openoutStorageDialog(scope.row)"
              type="text"
              style="color: #67c23a"
              icon="el-icon-message"
              >详情</el-button
            >
            <el-button
              size="mini"
              :disabled="
                scope.row.outStorageState != 0 && scope.row.outStorageState != 3
              "
              @click="eidtoutStorage(scope.row)"
              type="text"
              style="color: #409eff"
              icon="el-icon-edit"
              >编辑</el-button
            >
            <el-button
              :disabled="
                scope.row.outStorageState != 0 && scope.row.outStorageState != 3
              "
              size="mini"
              @click="deleteOutStorageHandel(scope.row)"
              type="text"
              style="color: #f56c6c"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 出库单详情 -->
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="outStorageDialogVisble"
    >
      <!-- 出库单详情 -->
      <el-row>
        <el-col :span="8">出库单号:{{ outStorageData.number }}</el-col>
        <el-col :span="8"
          >出库类型:{{ outStorageData.outStorageTypeDisplayName }}</el-col
        >
        <el-col :span="8"
          >出库单状态:{{ outStorageData.outStorageStateDisplayName }}</el-col
        >
        <el-col :span="8">客户名称:{{ outStorageData.owinUserName }}</el-col>
        <el-col :span="8">公司/个人:{{ outStorageData.companyName }}</el-col>
        <el-col :span="8">制单人:{{ outStorageData.userName }}</el-col>
        <el-col :span="8"
          >关联订单号:{{ outStorageData.associatedOrderNumber }}</el-col
        >
        <el-col :span="16">备注:{{ outStorageData.remark }}</el-col>
      </el-row>
      <!-- 出库单商品详情 -->
      <el-table :data="outStorageData.outStorageDetails" border>
        <el-table-column
          prop="productName"
          show-overflow-tooltip
          label="商品名称"
        >
        </el-table-column>
        <el-table-column prop="productAmount" label="商品数量">
        </el-table-column>
        <el-table-column prop="productPrice" label="商品价格">
        </el-table-column>
        <el-table-column label="型号">
          <template slot-scope="scope">
            {{ scope.row.modelName || scope.row.productModel || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.productSpecs || '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" show-overflow-tooltip label="商品备注">
        </el-table-column>
      </el-table>

      <!-- 审核进度 -->
      <el-row style="margin-top: 20px">
        <el-row>
          <span>审核进度</span>
          <el-button
            type="success"
            :disabled="canAudit"
            style="float: right"
            size="mini"
            @click="upAuditeClick"
            >提交审核</el-button
          >
        </el-row>
        <el-timeline style="margin-top: 20px">
          <el-timeline-item
            v-for="(log, index) in outStorageData.auditLogs"
            :key="index"
            :timestamp="log.createTime"
            :color="getTimeTimeLineColor(log)"
          >
            <el-card>
              <template v-if="log.auditState == 0">
                <el-tag type="info">等待审核中</el-tag>
                <span style="float: right">
                  <el-button
                    type="danger"
                    size="mini"
                    @click="openAuditCheckDialog(false)"
                    >不通过审核</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openAuditCheckDialog(true)"
                    >通过审核</el-button
                  >
                </span>
              </template>
              <el-tag v-else-if="log.auditState == 1" type="success"
                >审核成功</el-tag
              >
              <el-tag v-else type="danger">审核失败</el-tag>
              <el-row type="flex" style="margin-top: 20px">
                <el-image
                  v-for="(imgUrl, iindex) in log.fileUrlArr"
                  :key="'auditImg' + iindex"
                  style="width: 100px; height: 100px"
                  :src="imageBaseUrl + imgUrl"
                  :preview-src-list="[imageBaseUrl + imgUrl]"
                  fit="contain"
                ></el-image>
                <span style="padding: 10px; color: red">{{
                  log.auditRecommendations
                }}</span>
              </el-row>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-row>

      <!-- 收货进度 -->
      <el-row style="margin-top: 20px">
        <el-row
          ><span>收货进度:</span>
          <el-button
            type="success"
            :disabled="canGoods"
            style="float: right"
            size="mini"
            @click="opendeliveryGoodsDialog"
            >去出库</el-button
          ></el-row
        >

        <div
          v-for="(item, index) in outStorageData.deliveryGoods"
          :key="'inGood' + index"
        >
          <span>快递编号:</span
          ><el-link
            v-show="!item.isChange"
            type="primary"
            @click="opendeliveryGoodsDetialDialog(item.details)"
            >快递单号:{{ item.courierNumber }} 出库时间:{{
              item.createTime
            }}</el-link
          >
          <el-input
            :maxlength="30"
            class="input-new-tag"
            v-if="item.isChange"
            v-model="item.changeCourierNumber"
            :ref="'kd' + item.id"
            size="small"
            @keyup.enter.native="handleInputConfirm(item.id)"
            @blur="handleInputConfirm(item.id)"
          >
          </el-input>
          <el-link v-else type="info" @click="item.isChange = true"
            >修改</el-link
          >
        </div>
      </el-row>

      <!-- 审核建议对话框 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="aduitCheckDialog"
      >
        <el-form>
          <el-form-item label="审核意见:">
            <el-input
              type="text"
              v-model="auditForm.remark"
              placeholder="请输入审核意见"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="aduitCheckDialog = false"
            >取消</el-button
          >
          <el-button type="primary" @click="aduitCheckCheck">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 出库单 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        width="70%"
        :visible.sync="deliveryGoodsProductDialog"
      >
        <el-form inline>
          <el-form-item label="快递单号:">
            <el-input
              type="text"
              v-model="deliveryGoodsProductForm.customerNumber"
              placeholder="请输入快递单号"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row>
          <el-table
            :data="deliveryGoodsProductData"
            stripe
            style="width: 100%"
            border
            :key="tablekey"
          >
            <el-table-column width="40">
              <el-checkbox
                :indeterminate="isIndeterminate"
                slot="header"
                v-model="allCheck"
                @change="selectAllCheckChangeHandle"
              ></el-checkbox>
              <el-checkbox
                slot-scope="{ row }"
                v-model="row.isChecked"
              ></el-checkbox>
            </el-table-column>
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="型号">
              <template slot-scope="scope">
                {{ scope.row.modelName || scope.row.productModel || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '无' }}
              </template>
            </el-table-column>
            <el-table-column label="商品总数" prop="productAmount">
            </el-table-column>
            <el-table-column label="已发货数量" prop="sendAmount">
            </el-table-column>
            <el-table-column label="发货数量">
              <template slot-scope="scope">
                <el-input-number
                  :min="0"
                  :max="scope.row.productAmount - scope.row.sendAmount"
                  size="mini"
                  v-model="scope.row.count"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="info" @click="deliveryGoodsProductDialog = false"
            >取消</el-button
          >
          <el-button type="primary" @click="deliveryGoodsDialogCheck"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 出库单详情 -->
      <!-- 订单快递详情 -->
      <el-dialog
        :close-on-click-modal="false"
        append-to-body
        :visible.sync="deliveryGoodsDetialDialog"
      >
        <el-row>
          <el-table
            :data="deliveryGoodsDetialData"
            stripe
            style="width: 100%"
            border
          >
            <el-table-column type="index" label="#"> </el-table-column>
            <el-table-column label="商品名称" prop="productName">
            </el-table-column>
            <el-table-column label="发货数量" prop="amount"> </el-table-column>
            <el-table-column prop="modelName" label="型号"> </el-table-column>
            <el-table-column prop="productSpecs" label="规格">
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-checkbox v-model="showPrintRemark">打印备注</el-checkbox>
          <el-button size="mini" @click="printDataDialog = true"
            >预览打印出库单</el-button
          >
          <el-button size="mini" @click="zjPrintPage">直接打印</el-button>
        </el-row>

        <el-dialog append-to-body :visible.sync="printDataDialog">
          <!--startprint-->
          <div ref="printBox">
            <template
              v-for="i in Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
            >
              <div :key="'li' + i" class="print_box">
                <template v-if="i == 1">
                  <div class="print_box_head">
                    <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                    <barcode
                      class="barcode_style"
                      :width="1"
                      :font-size="12"
                      :height="25"
                      :value="outStorageData.number"
                    />
                  </div>
                  <div class="print_box_info">
                    <div>
                      出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                    </div>
                    <div>
                      业务类型&nbsp;{{
                        outStorageData.outStorageTypeDisplayName
                      }}
                    </div>
                    <div>仓库&nbsp;杭州</div>
                    <div>出库类别&nbsp;发货</div>
                    <div>销售部门&nbsp;国内销售</div>
                    <div>客户&nbsp;{{ outStorageData.owinUserName }}</div>
                    <div v-if="showPrintRemark" style="width: 100%">
                      备注&nbsp;{{ outStorageData.companyName }}&nbsp;{{
                        outStorageData.remark
                      }}
                    </div>
                  </div>
                </template>
                <div class="print_table_box">
                  <table
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    style=""
                    width="700"
                  >
                    <thead v-if="i == 1">
                      <tr>
                        <th>库存编号</th>
                        <th>存货名称</th>
                        <th>规格型号</th>
                        <th>主计量单位</th>
                        <th>数量</th>
                        <th>备注</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="item in pagination(
                          i,
                          10,
                          deliveryGoodsDetialDataComp
                        )"
                      >
                        <tr v-if="!item.isSoft" :key="item.id">
                          <td width="80">{{ item.productNo }}</td>
                          <td width="260">
                            {{ item.productName }}
                          </td>
                          <td width="100">
                            {{
                              (item.modelName || item.productModel || '') +
                              '' +
                              (item.productSpecs || '')
                            }}
                          </td>
                          <td width="70">{{ item.productUnit }}</td>
                          <td width="80">{{ item.amount }}</td>
                          <td width="90">{{ '' }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="print_box_foot">
                  <template
                    v-if="
                      i == Math.ceil(deliveryGoodsDetialDataComp.length / 10)
                    "
                  >
                    <div>制单人&nbsp;智美OA</div>
                    <div>审核人</div>
                    <div style="flex: 1; text-align: right">
                      总计:{{ deliveryGoodsDetialCount }}件
                    </div>
                  </template>

                  <div style="flex-grow: 1; text-align: right">
                    第{{ i }}页&nbsp;共{{
                      Math.ceil(deliveryGoodsDetialDataComp.length / 10)
                    }}页
                  </div>
                </div>
              </div>
              <div
                v-if="i != Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
                :key="'pageNex' + i"
                style="page-break-after: always"
              ></div>
            </template>
          </div>
          <!--endprint-->
          <div slot="footer" class="dialog-footer">
            <el-button @click="printDataDialog = false">取 消</el-button>
            <el-button type="primary" @click="printpage">打 印</el-button>
          </div>
        </el-dialog>
        <!-- 直接打印部分 start -->
        <div style="display: none" ref="zhijieprintBox">
          <template
            v-for="i in Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
          >
            <div :key="'li' + i" class="print_box">
              <template v-if="i == 1">
                <div class="print_box_head">
                  <img :src="snowyiPng" />杭州雪意科技有限公司销售出库单
                  <barcode
                    class="barcode_style"
                    :width="1"
                    :font-size="12"
                    :height="25"
                    :value="outStorageData.number"
                  />
                </div>
                <div class="print_box_info">
                  <div>
                    出库日期&nbsp;{{ new Date() | timeFormat('yyyy-MM-dd') }}
                  </div>
                  <div>
                    业务类型&nbsp;{{ outStorageData.outStorageTypeDisplayName }}
                  </div>
                  <div>仓库&nbsp;杭州</div>
                  <div>出库类别&nbsp;发货</div>
                  <div>销售部门&nbsp;国内销售</div>
                  <div>客户&nbsp;{{ outStorageData.owinUserName }}</div>
                  <div v-if="showPrintRemark" style="width: 100%">
                    备注&nbsp;{{ outStorageData.companyName }}&nbsp;{{
                      outStorageData.remark
                    }}
                  </div>
                </div>
              </template>
              <div class="print_table_box">
                <table
                  align="center"
                  cellPadding="0"
                  cellSpacing="0"
                  style=""
                  width="700"
                >
                  <thead v-if="i == 1">
                    <tr>
                      <th>库存编号</th>
                      <th>存货名称</th>
                      <th>规格型号</th>
                      <th>主计量单位</th>
                      <th>数量</th>
                      <th>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="item in pagination(
                        i,
                        10,
                        deliveryGoodsDetialDataComp
                      )"
                    >
                      <tr v-if="!item.isSoft" :key="item.id">
                        <td width="80">{{ item.productNo }}</td>
                        <td width="260">
                          {{ item.productName }}
                        </td>
                        <td width="100">
                          {{
                            (item.modelName || item.productModel || '') +
                            ' ' +
                            (item.productSpecs || '')
                          }}
                        </td>
                        <td width="70">{{ item.productUnit }}</td>
                        <td width="80">{{ item.amount }}</td>
                        <td width="90">{{ '' }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="print_box_foot">
                <template
                  v-if="i == Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
                >
                  <div>制单人&nbsp;智美OA</div>
                  <div>审核人</div>
                  <div style="flex: 1; text-align: right">
                    总计:{{ deliveryGoodsDetialCount }}件
                  </div>
                </template>

                <div style="flex-grow: 1; text-align: right">
                  第{{ i }}页&nbsp;共{{
                    Math.ceil(deliveryGoodsDetialDataComp.length / 10)
                  }}页
                </div>
              </div>
            </div>
            <div
              v-if="i != Math.ceil(deliveryGoodsDetialDataComp.length / 10)"
              :key="'pageNex' + i"
              style="page-break-after: always"
            ></div>
          </template>
        </div>
        <!-- 直接打印部分 end -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deliveryGoodsDetialDialog = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-dialog>
  </el-card>
</template>
<script>
import snowyiPng from '@/assets/snowyi.png'
import VueBarcode from 'vue-barcode'
import { timeOffset } from '@/utils/zoneTime.js'
import clip from '@/utils/clipboard'
import {
  getAll,
  getOutStorageTypeOptions,
  getInfoById,
  upAudite,
  auditOrder,
  outGoods,
  updateKd,
  deleteOutStorage,
  getOutStorageState,
} from '@/api/outStorage.js'
export default {
  data() {
    return {
      snowyiPng: snowyiPng + '?' + new Date(),
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        type: null,
        status: null,
      },
      timer: '',
      expandRowIds: [],
      outStorageDataList: [],
      typeOptions: [],
      stateOptions: [],
      // 出库订单详情对话框显示隐藏
      outStorageDialogVisble: false,
      outStorageData: {
        number: '',
        outStorageType: -1,
        outStorageTypeDisplayName: '',
        outStorageState: -1,
        outStorageStateDisplayName: '',
        userName: '',
        remark: '',
        outStorageDetails: [],
        auditLogs: [],
        deliveryGoods: [],
      },
      // 审核对话框建议内容
      aduitCheckDialog: false,
      auditForm: {
        isCheck: false,
        remark: '',
      },
      allCheck: true,
      isIndeterminate: false,
      // 发货订单的数据
      deliveryGoodsProductData: [],
      deliveryGoodsProductDialog: false,
      // 出库单表单
      deliveryGoodsProductForm: {
        customerNumber: '',
        productList: [],
      },
      tablekey: false,
      deliveryGoodsDetialDialog: false,
      deliveryGoodsDetialData: [],
      // 打印出货单部分
      showPrintRemark: true,
      printDataDialog: false,
    }
  },
  components: {
    barcode: VueBarcode,
  },
  created() {
    this.getTableData()
    this.getOptions()
    this.getStateOptions()
  },
  methods: {
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    async getOptions() {
      let { data } = await getOutStorageTypeOptions()
      this.typeOptions = data
    },
    async getStateOptions() {
      let { data } = await getOutStorageState()
      this.stateOptions = data
    },
    async getTableData() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getAll(filterData)
      this.outStorageDataList = data.data.map((item) => {
        item.loading = false
        item.productList = null
        return item
      })
      this.queryFilter.total = data.total
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    pushAddPage() {
      this.$router.push({ path: '/AddOutStorage' })
    },
    async expandChange(row, expandedRows) {
      if (!row.productList) {
        row.loading = true
        try {
          let data = await getInfoById(row.id)

          this.outStorageDataList.forEach((item, index, arr) => {
            if (item.id == row.id) {
              arr[index].productList = data.data.outStorageDetails
            }
          })

          row.loading = false
        } catch (error) {
          row.loading = false
        }
      }
    },
    // 打开出库单详情显示页面
    async openoutStorageDialog(row) {
      let data = await getInfoById(row.id)
      this.outStorageData = data.data
      this.outStorageDialogVisble = true
    },
    // 获取时间线对应的图标颜色
    getTimeTimeLineColor(log) {
      if (log.auditState == 0) {
        return '#909399'
      }

      if (log.auditState == 1) {
        return '#5CB87A'
      }

      if (log.auditState == 2) {
        return '#F56C6C'
      }

      return '#909399'
    },
    openAuditCheckDialog(isCheck) {
      this.auditForm.isCheck = isCheck
      this.aduitCheckDialog = true
    },
    // 提交审核申请
    async upAuditeClick() {
      await upAudite(this.outStorageData.id)

      let { data } = await getInfoById(this.outStorageData.id)

      this.outStorageData = data

      this.getTableData()
    },
    // 提交审核意见
    async aduitCheckCheck() {
      if (this.auditForm.isCheck == false && this.auditForm.remark == '') {
        return this.$message.error('请输入不予通过的理由')
      }

      await auditOrder(
        this.outStorageData.id,
        this.auditForm.isCheck,
        this.auditForm.remark
      )

      let { data } = await getInfoById(this.outStorageData.id)
      this.outStorageData = data

      this.getTableData()

      this.aduitCheckDialog = false
    },
    // 打开收货对话框
    opendeliveryGoodsDialog() {
      let productdeliveryGoods =
        this.outStorageData.deliveryGoods?.reduce(function (pre, cur) {
          return [...pre, ...cur.details]
        }, []) ?? []

      let productList = [...this.outStorageData.outStorageDetails].map(
        (item) => {
          item['sendAmount'] = 0
          item['count'] = item.productAmount
          return item
        }
      )

      productdeliveryGoods.forEach((item) => {
        let filterItme = productList.filter((pItem) => {
          return (
            pItem.productId == item.productId &&
            pItem.modelName == item.modelName &&
            pItem.productSpecs == item.productSpecs
          )
        })

        if (filterItme && filterItme.length > 0) {
          filterItme[0].sendAmount = parseInt(
            Number(filterItme[0].sendAmount) + Number(item.amount)
          )

          filterItme[0].count = parseInt(
            parseInt(filterItme[0].productAmount) -
              parseInt(filterItme[0].sendAmount)
          )
        }
      })

      productList = productList.filter((item) => item.count > 0)

      this.deliveryGoodsProductData = productList.map((item) => {
        return { ...item, isChecked: true }
      })

      this.allCheck = true
      this.isIndeterminate = false

      this.deliveryGoodsProductDialog = true
    },
    selectAllCheckChangeHandle(val) {
      this.deliveryGoodsProductData = this.deliveryGoodsProductData.map(
        (item) => {
          item.isChecked = val
          return item
        }
      )

      this.isIndeterminate = false
      this.allCheck = val

      // this.tablekey = !this.tablekey
    },
    async deliveryGoodsDialogCheck() {
      if (this.deliveryGoodsProductForm.customerNumber == '') {
        return this.$message.error('当前快递信息不能为空')
      }

      let prodd = [...this.deliveryGoodsProductData]

      prodd = prodd.filter((item) => {
        return item.count && item.count != 0 && item.isChecked
      })
      prodd = prodd.map((item) => {
        return {
          productId: item.productId,
          modelName: item.modelName,
          productSpecs: item.productSpecs,
          productName: item.productName,
          amount: item.count,
        }
      })
      await outGoods(
        this.outStorageData.id,
        this.deliveryGoodsProductForm.customerNumber,
        prodd
      )

      let { data } = await getInfoById(this.outStorageData.id)
      this.outStorageData = data

      this.getTableData()

      this.deliveryGoodsProductDialog = false
    },
    // 打开出库详情对话框
    opendeliveryGoodsDetialDialog(data) {
      this.deliveryGoodsDetialData = data
      this.deliveryGoodsDetialDialog = true
    },
    eidtoutStorage(row) {
      this.$router.push({ path: '/EditOutStorage/' + row.id })
    },
    // 表格尾行合计行
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // 第一列索引
        if (index === 0) {
          sums[index] = '总价'
          return
        }

        // 第二列商品名称
        if (index === 2) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const values = data.map((item) => Number(item[column.property]))
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return prev + curr
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ' 件'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        // 第四列总价
        if (index === 3) {
          if (!data) {
            sums[index] = 'N/A'
          } else {
            const priceArr = data.map((item) => {
              return Number(item['productPrice'] * item['productAmount'])
            })

            if (!priceArr.every((value) => isNaN(value))) {
              sums[index] = priceArr
                .reduce((prev, curr) => {
                  const value = Number(curr)
                  if (!isNaN(value)) {
                    return prev + curr
                  } else {
                    return prev
                  }
                }, 0)
                .toFixed(2)
              sums[index] += ' 元'
            } else {
              sums[index] = 'N/A'
            }
          }
          return
        }

        sums[index] = ''
      })

      this.$nextTick((_) => {
        this.$refs['productRef'].doLayout()
      })

      return sums
    },
    // 打印出货单
    printpage() {
      let newStr = this.$refs.printBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
    zjPrintPage() {
      let newStr = this.$refs.zhijieprintBox.innerHTML
      window.document.body.innerHTML = newStr // 把需要打印的指定内容赋给body
      window.print() // 调用浏览器的打印功能打印指定区域
      location.reload()
    },
    pagination(pageNo, pageSize, array) {
      var offset = (pageNo - 1) * pageSize
      return offset + pageSize >= array.length
        ? array.slice(offset, array.length)
        : array.slice(offset, offset + pageSize)
    },
    async handleInputConfirm(id) {
      var entity = this.outStorageData.deliveryGoods.filter((item) => {
        return item.id == id
      })

      if (!entity[0]?.changeCourierNumber) {
        entity[0].changeCourierNumber = entity[0].courierNumber
        entity[0].isChange = false
        return
      }
      try {
        let { data: data1 } = await updateKd({
          orderId: this.outStorageData.id,
          id,
          number: entity[0].changeCourierNumber,
        })
      } catch (error) {}

      let { data } = await getInfoById(this.outStorageData.id)
      this.outStorageData = data

      this.getTableData()
    },
    deleteOutStorageHandel(row) {
      this.$confirm('此操作将删除当前出库单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteOutStorage(row.id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getTableData()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '删除失败',
          })
        })
    },
    tagType(val) {
      if (val == 0 || val == 1 || val == 2 || val == 4) {
        return 'warning'
      }
      if (val == 3) {
        return 'danger'
      }

      return 'success'
    },
  },
  computed: {
    canAudit() {
      return (
        this.outStorageData.outStorageState != 0 &&
        this.outStorageData.outStorageState != 3
      )
    },
    canGoods() {
      return (
        // 待发货状态
        this.outStorageData.outStorageState != 2 &&
        // 部分发货状态
        this.outStorageData.outStorageState != 4
      )
    },
    deliveryGoodsDetialDataComp() {
      return this.deliveryGoodsDetialData?.filter((u) => {
        return u.isSoft == false
      })
    },
    deliveryGoodsDetialCount() {
      return this.deliveryGoodsDetialDataComp.reduce((pre, cur) => {
        pre += cur.amount
        return pre
      }, 0)
    },
  },
}
</script>
<style lang="scss" scoped>
.input-new-tag {
  width: 100px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-select {
  width: 100%;
}
.expand_box {
  padding: 10px 20px;
  box-sizing: border-box;
}
.print_box {
  width: 700px;
  border: 1px solid transparent;
  margin: 100px auto 0;
  font-size: 16px;
  .print_box_head {
    position: relative;
    height: 50px;
    text-align: center;
    line-height: 50px;
    .barcode_style {
      position: absolute;
      top: 0;
      right: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
    }
  }
  .print_box_info {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 230px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
  .print_table_box {
    font-size: 12px;
    table {
      border-top: 1px solid black;
      border-left: 1px solid black;
      th,
      td {
        border-right: 1px solid black;
        border-bottom: 1px solid black;
      }
    }
    thead {
      line-height: 28px;
      th {
        font-weight: normal;
      }
    }
    tbody {
      line-height: 16px;
      td {
        padding-left: 5px;
        box-sizing: border-box;
      }
    }
  }
  .print_box_foot {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 10px;
    div {
      width: 266px;
      flex-shrink: 0;
      margin-bottom: 5px;
    }
  }
}
</style>
